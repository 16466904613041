<template>
  <div>
    <div class='row'>
      <div class='col-lg-4 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <vb-headers-card-header :data="{ title: 'Şikayet Bilgisi' }" />
          </div>
          <div class='card-body'>
            <div>
              <dl class='row'>
                <dt class='col-sm-4'>Şikayetçi:</dt>
                <dd class='col-sm-8'>
                  {{ complaint.complainant?.name }}
                </dd>
                <a-divider></a-divider>
                <dt class='col-sm-4'>Şikayet Tarihi:</dt>
                <dd class='col-sm-8'>
                  {{ complaint.complaintDate !== '' ? moment(complaint.complaintDate).format('LL, HH:mm') : 'Tarih Bilgisi Yok' }}
                </dd>
                <a-divider></a-divider>
                <dt class='col-sm-4'>Durum:</dt>
                <dd class='col-sm-8'>
                  <span :class="complaint.complaintState === 'Seen' ? 'badge badge-primary' : 'badge badge-danger'">
                    {{ complaint.complaintState === 'Seen' ? 'Görüldü' : 'Görülmedi' }}
                  </span>
                </dd>
                <a-divider></a-divider>
                <dt class='col-sm-4 text-truncate'>Şikayet Edilen Kişi</dt>
                <dd class='col-sm-8'>
                  {{ complaint.complained?.name }}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-8 col-md-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header'>
            <h6 class='mb-0'>
              <strong>{{ complaint.title }}</strong>
            </h6>
          </div>
          <div class='card-body'>
            <!-- TİPOGRAFİ 2 -->
            <div>
              <p>
                {{ complaint.complaintDescription }}
              </p>
            </div>
          </div>
          <div v-if='complaint.complaintState !== "Seen"' class='card-body'>
            <a-button @click='changeStateToSeen' class='btn btn-primary'>Okundu Olarak İşaretle</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { CustomerServiceAdministration } from '../../services/api-service'
import trLocale from 'moment/locale/tr'

moment.updateLocale('tr', trLocale)

export default {
  name: 'VbIkayetDetaylar',
  data() {
    const id = parseInt(this.$route.params['id'])
    return {
      id,
      changeComplaintToSeen: '',
      loading: false,
      complaint: {},
    }
  },
  created: function() {
    this.moment = moment
  },
  mounted() {
    this.getComplaintInfo()
  },
  methods: {
    async getComplaintInfo() {
      this.loading = true

      try {
        const { data } = await CustomerServiceAdministration.getComplaint(this.id)
        this.complaint = data
      } catch {
      }

      this.loading = false
    },
    async changeStateToSeen() {
      if (typeof this.id === 'number') {
        await CustomerServiceAdministration.changeComplaintStateToSeen({ id: this.id })
        this.complaint.complaintState = 'Seen'
      }
    },
  },
}
</script>
